@import "styles/base/config";
@import "styles/base/typography";

.list {
  list-style: none;

  .item {
    display: flex;
    margin-bottom: 1rem;

    .icon {
      margin-top: 0.3rem;
      width: 2rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    .text {
      @include paragraph-2-primary;
      @include radial-gradient-text-dark;
      font-weight: 700;
    }
  }
}
