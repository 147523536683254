// SegoeUI
@font-face {
  font-family: "SegoeUI";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/segoeUI/SegoeUI.woff2") format("woff2"),
    url("../../assets/fonts/segoeUI/SegoeUI.woff") format("woff");
}

@font-face {
  font-family: "SegoeUI";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/segoeUI/SegoeUI-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/segoeUI/SegoeUI-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "SegoeUI";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/segoeUI/SegoeUI-Bold.woff2") format("woff2"),
    url("../../assets/fonts/segoeUI/SegoeUI-Bold.woff") format("woff");
}

// NeueBit

@font-face {
  font-family: "NeueBit";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/neueBit/NeueBit-Regular.woff2") format("woff2"),
    url("../../assets/fonts/neueBit/NeueBit-Regular.woff") format("woff");
}

@font-face {
  font-family: "NeueBit";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/neueBit/PPNeueBit-Bold.woff2") format("woff2"),
    url("../../assets/fonts/neueBit/PPNeueBit-Bold.woff") format("woff");
}
