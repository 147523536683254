@import "styles/base/config";
@import "styles/base/typography";
@import "../../../../../../components/Input/Input.module.scss";

$transition-duration: 500ms;
$border-color: rgba(255, 255, 255, 0.1);
$border-bg-hover: linear-gradient(
  100.7deg,
  rgba(255, 255, 255, 0.4) -9.86%,
  rgba(0, 0, 0, 0) 75.84%
);
$border-bg-error: linear-gradient(
  94.2deg,
  rgba(255, 97, 173, 0.4) 8.65%,
  rgba(255, 97, 173, 0) 112.63%
);
$border-radius: 2.8rem;
$border-width: 0.15rem;
$border-hover-width: 0.3rem;

.cardInputContainer {
  @include input-container;
}

.input {
  @include input;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  width: 100%;

  @include mobile {
    background: transparent;
  }
}

.emailInput {
  @include mobile {
    background: transparent;
  }
}

.cardInvalid {
  color: $color-error;
}

.cardBase {
  width: 100%;
  outline: none;
  caret-color: $color-text-primary;
}

.root .button {
  display: flex;
  justify-content: center;
  margin-top: 2.8rem;
}

.divider {
  height: 1.5rem;
  width: 100%;
}
