@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

$start-opacity: 0.15;
$max-opacity: 1;
$element-count: 4;
$increment: ($max-opacity - $start-opacity) / $element-count;
$delay: 0.6s;

.root {
  @include not-selectable;
  width: 100%;
  background-size: contain;
  background-image: url(../../../../../../assets/images/payment/head_blurred.png);
  background-position: center;
  background-repeat: no-repeat;
  padding: 5rem;
  @include subtitle-1-secondary;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease;
  width: 100%;
  height: 100%;

  @include mobile {
    padding: 3rem;
  }

  &.shown {
    pointer-events: initial;
    opacity: 1;
  }

  .element {
    color: $color-text-primary;
    opacity: 0;
  }

  @for $i from 1 through $element-count {
    .element:nth-child(#{$i}) {
      $opacity: $start-opacity + ($increment * ($i - 1));
      color: rgba(255, 255, 255, $opacity);
      animation: loading-text-appearing 2s $delay * $i forwards;
      margin-bottom: 1rem;
    }
  }
}

// keyframes

@keyframes loading-text-appearing {
  to {
    opacity: 1;
  }
}
