@import "styles/base/typography";

.root {
  @include not-selectable;
}

.modal {
  @include not-selectable;
}

.modalButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.modalButton {
  margin-top: 4rem;
}
