@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

.root {
  @include not-selectable;
  padding: 8rem 17rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 10;

  @include tabletSmall {
    padding: 9rem 11rem;
  }

  @include desktop {
    padding: 8rem 9rem;
  }

  @include mobile {
    padding: 8rem 6rem;
  }
}

.title {
  @include heading-1-secondary;
  display: flex;
  align-items: center;
}

.letters {
  color: inherit;
  font-weight: 700;
  opacity: 0;
  animation: letter-move 0.5s cubic-bezier(0.41, 0, 0.06, 1) forwards;
}

// name variable from html template needs to be copied here
$name: "Kodai";
// for delay
$additional-length: 5;
$i: str-length($name) + $additional-length;

@while $i > -1 {
  .letters-#{$i} {
    animation-delay: 0.09s * $i;
    color: $color-text-primary_darker;
  }
  $i: $i - 1;
}

.percentage {
  @include heading-2-primary;
  display: flex;
  justify-content: right;
  position: absolute;
  z-index: 10;
  bottom: 8rem;
  right: 17rem;

  @include tabletSmall {
    right: 15rem;
  }

  @include tabletSmall {
    right: 10rem;
  }

  @include desktop {
    right: 9rem;
  }

  @include mobile {
    right: 6rem;
  }
}

.empty {
  width: 23px;
}

// keyframes

@keyframes letter-move {
  0% {
    opacity: 0;
    transform: translateY(20px);
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    transform-origin: 50% 50%;
  }
}
