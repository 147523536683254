@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

$features_bg: #0d0f20;

@mixin container-border {
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
}

@mixin container {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(255, 255, 255, 0.1),
    inset 0px 1px 0px rgba(255, 255, 255, 0.08);
  border-radius: 0.7rem;
}

.root {
  @include container;
  height: 100%;
  width: 100%;
  display: flex;
  height: 21rem;
  background-color: $features_bg;
}

.initial {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}

.initialIcon {
  width: 4.2rem;
  height: 4.2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: grayscale(0.75);
  margin-bottom: 1rem;
}

.initialTitle {
  @include title-2-primary;
  @include linear-gradient-text;
  margin-bottom: 1rem;
}

.featuresContainer {
  @include container-border;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  gap: 0px 1rem;
  background: rgba(255, 255, 255, 0.04);

  border-radius: 0.7rem;
  margin: 1rem;
  padding: 1.2rem;
  position: relative;
  width: 100%;
}

.column {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 6.3rem auto 1fr;
  gap: 0.5rem 2rem;
  grid-template-areas:
    "icon"
    "title"
    "info";
  position: relative;
  overflow: hidden;
  user-select: none;
}

.columnDivider {
  background: $color-divider;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: calc(100% - 6rem);
  width: 0.1rem;
}

.iconArea {
  grid-area: icon;
  justify-content: center;
  display: flex;
  align-items: center;

  .iconContainer {
    margin: 0 auto;
    background: radial-gradient(
      114.82% 120.63% at 50% 50%,
      #302253 0%,
      #1c102f 100%
    );
    mix-blend-mode: luminosity;
    border-radius: 70px;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0.1rem solid #434451;
  }
}
.titleArea {
  grid-area: title;
  text-align: center;

  .title {
    @include subtitle-1-secondary;
    @include truncateText(1);
    color: $color-text-primary;
    max-width: 34rem;
    margin: 0 auto;
  }
}

.infoArea {
  grid-area: info;
  @include paragraph-3-primary;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 33rem;
  @include truncateText(4);
  .infoDescription {
    @include truncateText(4);
    white-space: pre-wrap;
  }
}

.additionalListContainer {
  @include paragraph-2-primary;
  @include container-border;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
  background: $background-dark;
  backdrop-filter: blur(9.5px);
  border-radius: 0.7rem;
  padding: 1rem;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  white-space: pre-wrap;
  height: 100%;
  text-align: center;
  max-height: 100%;
  display: flex;

  .additionalListDescription {
    margin: auto;
  }
}

.additionalListActive {
  opacity: 1;
  overflow: auto;
}

.emptyText {
  @include title-1-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
