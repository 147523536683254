@import "styles/base/config";
@import "styles/base/responsive";
@import "styles/base/typography";

.root {
  backdrop-filter: blur(8.71199px);
  position: relative;
  margin-top: 3rem;
  opacity: 0.85;
  margin: 0.2rem;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    border-radius: 1rem;
    background-image: var(--backgroundImage);
  }
}

.contentContainer {
  position: relative;
  background: #151726;
  border-radius: 1rem;
  padding: 1.5rem 1.6rem;

  @include tablet {
    padding: 1.1rem 1.9rem;
  }

  &::before {
    pointer-events: none;
    content: "";
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: -1;
    position: absolute;
    display: block;
    border-radius: 1rem;
    width: calc(100% + 0.4rem);
    height: calc(100% + 0.4rem);
    background: var(--borderGradient);
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}

.left {
  line-height: 1.2;
}

.icon {
  flex-shrink: 0;
  margin-right: 0.9rem;
  width: 2.4rem;
  height: 2.4rem;
  margin-bottom: -0.4rem;

  @include tablet {
    margin-bottom: -0.3rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.text {
  @include subtitle-2-primary;
  font-weight: 600;
  color: $color-text-primary;
  text-align: center;
}
