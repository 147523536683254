@import "styles/base/config";
@import "styles/base/typography";

.errorMessage {
  color: $color-error;
  font-weight: 400;
  font-size: 1.1rem;

  @include mobile {
    font-size: 1.4rem;
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  & > p {
    margin: 0.3rem 0;

    @include mobile {
      margin: 0.5rem 0;
    }
  }
}
