@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

.root {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.sites {
  display: flex;
  flex-wrap: wrap;
}

.card {
  position: absolute;
  display: flex;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem;
}

.emptyText {
  @include title-1-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cardImage {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @include mobile {
    max-width: 14rem;
    height: 9rem;
  }

  @include mobileSmall {
    width: 8rem;
    height: 7rem;
  }

  &.selectedCard {
    width: 100%;
    height: 100%;

    @include mobile {
      width: 17rem;
      height: 12rem;
    }

    @include mobileSmall {
      width: 14rem;
      height: 12rem;
    }
  }
}
