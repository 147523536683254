@import "styles/base/config";
@import "styles/base/responsive";
@import "styles/base/typography";

.root {
  border-radius: 1.9rem;
  overflow: hidden;

  @include mobile {
    width: calc(100% / 2);
    height: 100%;
  }
}

.wrapper {
  @include mobile {
    height: 100% !important;
  }
}

.image {
  width: 100%;
  display: flex;
}

.loading {
  @include primary-pulse;
  width: 100%;
  height: 100%;

  z-index: 9;
  position: absolute;
}

.hidden {
  z-index: -1;
  animation: none;
  opacity: 0;
}
