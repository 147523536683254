@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

.root {
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: scroll 10s linear infinite;
  min-width: 20rem;

  @include tablet {
    min-width: 19rem;
  }

  @include mobile {
    min-width: 18rem;
  }

  .separator {
    position: relative;
    width: 1rem;
    height: 100%;
    margin-right: 1.5rem;

    @include tablet {
      margin-top: -0.3rem;
    }

    @include mobile {
      margin-right: 1rem;
      margin-top: -0.3rem;
    }
  }
  .text {
    display: inline-block;
    text-align: left;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-500%);
  }
}
