@import "styles/base/config";
@import "styles/base/typography";

$transition-duration: 500ms;
$border-color: rgba(255, 255, 255, 0.1);
$border-bg-hover: linear-gradient(
  100.7deg,
  rgba(255, 255, 255, 0.4) -9.86%,
  rgba(0, 0, 0, 0) 75.84%
);
$border-bg-error: linear-gradient(
  94.2deg,
  rgba(255, 97, 173, 0.4) 8.65%,
  rgba(255, 97, 173, 0) 112.63%
);
$border-radius: 2.8rem;

$border-width: 0.15rem;
$border-hover-width: 0.3rem;

@mixin input-container {
  display: flex;
  position: relative;
  margin: 0.4rem;

  // gradient border
  &::before {
    position: absolute;
    content: "";
    display: block;
    width: calc(100% + 0.2rem);
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    z-index: 0;
    height: calc(100% + 0.2rem);
    border-radius: $border-radius;
    background: $border-bg-hover;
    transition: opacity $transition-duration ease;

    @include tablet {
      background: transparent;
    }
  }

  &:not(.disabled) {
    &:focus,
    &:focus-visible,
    &:hover {
      &::before {
        opacity: 1;
      }

      .input {
        border-color: transparent;

        @include tablet {
          border-color: $border-color;
        }
      }
    }

    &.error {
      &:not(.disabled) {
        &:focus,
        &:focus-visible,
        &:hover {
          &::before {
            opacity: 0;
          }
          .input {
            border-color: $border-color;
          }
        }
      }
    }
  }

  &.error {
    @include tablet {
      background: transparent;
    }
    &::before {
      opacity: 1;
      border: $border-width solid $color-error;

      @include tablet {
        background: transparent;
      }
    }
    .input {
      border-color: transparent;

      color: $color-error;
    }
  }

  &.disabled {
    .input {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

@mixin input {
  @include paragraph-2-primary;
  color: $color-text-primary;
  background: #151625;
  backdrop-filter: blur(8.71199px);
  height: 4.6rem;
  border-radius: $border-radius;
  padding: 0 2rem;
  border: $border-width solid $border-color;
  transition: border-color $transition-duration ease;
  outline: none;
  caret-color: $color-text-primary;
  width: 100%;

  @include tablet {
    background: transparent;
    backdrop-filter: blur(0);
  }

  @include tablet {
    font-size: 1.6rem;
  }

  &::placeholder {
    opacity: 1;
    color: rgba(255, 255, 255, 0.32);
    font-weight: 400;
  }
}

.inputContainer {
  @include input-container;
}

.input {
  @include input;
}

.errorMessage {
  margin: 0.3rem 0 0.5rem 0.4rem;
}
