@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

.root {
  @include not-selectable;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15rem 2.5rem 5rem 2.5rem;

  @include tablet {
    padding: 2rem;
    position: relative;
  }
}

.sites {
  display: inline-flex;
  flex: 1;
  margin: 1rem;
}

.featuresContainer {
  margin-top: 1rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  @include tablet {
    pointer-events: none;
    align-self: center;
    position: absolute;
    bottom: 0;
    bottom: 3rem;
    margin-top: 0;
    &.hidden {
      display: none;
    }
  }

  @include mobile {
    bottom: 2rem;
  }
}
