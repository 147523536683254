@import "styles/base/config";
@import "styles/base/responsive";

.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.tabs {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 2;
  top: 2.5rem;

  @include tablet {
    top: initial;
    bottom: 3rem;
  }
}

.regionsContent,
.productCardsContent {
  @include tablet {
    .content {
      padding-bottom: 0;
    }
  }
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include tablet {
    padding-bottom: 14rem;
  }

  .hiddenTab {
    display: none;
  }
}
