@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

@mixin bg($img) {
  background-image: url($img);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

$content-replacement-delay: 4s;
$content-replacement-duration: 0.5s;

.initialLoading {
  animation: content-hiding-start $content-replacement-duration ease
      $content-replacement-delay forwards,
    content-hiding-end 0s $content-replacement-duration +
      $content-replacement-delay forwards;
}

.content {
  display: flex;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../../assets/background/stars.png");
  animation: content-appearing $content-replacement-duration ease
    $content-replacement-duration + $content-replacement-delay forwards;

  @include tablet {
    flex-direction: column-reverse;
    display: flex;
    height: 100vh;
    max-height: -webkit-fill-available;
  }
}

.tabsSide {
  width: 51%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: -0.1rem;
    display: block;
    height: 10rem;
    width: 0.3rem;
    background: $background-light;
    box-shadow: 0px 0px 15px 2px rgba(255, 255, 255, 0.7);
    border-radius: 2.7rem;
    filter: blur(0.7px);
    animation: line 7s cubic-bezier(1, 0, 0.67, 1) infinite;
    z-index: 9;
    @include tablet {
      display: none;
    }
  }

  @include tablet {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }
}

.formSide {
  width: 49%;
  display: flex;
  flex-direction: column;

  .formContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    @include tablet {
      overflow: hidden;
    }
  }

  @include tablet {
    width: 100%;
    max-height: 75rem;
    top: 0;
    z-index: 99;
  }
}

.root {
  height: 100%;
  position: relative;
  background: $background-main;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  &::after {
    content: "";
    @include bg("../../../assets/background/stars.png");
    top: 0;
    right: 0;
    pointer-events: none;
    background-position: center;
    animation: fade-in-from-none-and-fide 3.967s ease 0.7s forwards;
  }

  &:before {
    content: "";
    pointer-events: none;
    z-index: 9;
    animation: fade-in-from-none 3.967s ease 0.2s forwards;
    @include bg("../../../assets/background/footer-gradient.svg");
    -webkit-transform: translate3d(0, 0, 0);
    @include tablet {
      @include bg("../../../assets/background/footer-gradient-tablet.svg");
      animation: fade-in-from-none 3.967s ease 0.2s forwards,
        replace-flip 2s ease 4s forwards;
      z-index: 0;
    }

    @include mobile {
      @include bg("../../../assets/background/footer-gradient-mobile.svg");
      animation: fade-in-from-none 3.967s ease 0.2s forwards,
        replace-flip 2s ease 4s forwards;
    }
  }

  &.available {
    &::before {
      z-index: -1;
    }
  }

  &.loading {
    &::before {
      z-index: -1;
    }
  }

  &.success {
    &::before {
      opacity: 0;
      background-image: url(../../../assets/background/footer-radial-gradient.svg);
      animation: footer-animation 3.967s ease 200ms forwards;
    }
  }

  &.error {
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &::before {
      opacity: 0;
      background-image: url(../../../assets/background/footer-gradient-red.svg);
      animation: footer-animation 3.967s ease 200ms forwards;
    }
  }
}

// keyframes

@keyframes fade-in-from-none {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-from-none-and-fide {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  98% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes content-hiding-start {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes content-hiding-end {
  0% {
    height: 100%;
  }
  100% {
    padding: 0;
    height: 0;
    overflow: hidden;
  }
}

@keyframes content-appearing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    pointer-events: initial;
  }
}

@keyframes footer-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes line {
  0% {
    top: -15rem;
  }
  50% {
    top: calc(100% + 15rem);
  }
  100% {
    top: -15rem;
  }
}

@keyframes replace-flip {
  0% {
    opacity: 1;
  }

  1% {
    opacity: 0;
  }

  30% {
    opacity: 0;
    transform: rotate(180deg) scaleX(-1);
    filter: blur(91px);
  }

  80% {
    opacity: 1;
    transform: rotate(180deg) scaleX(-1);
    filter: blur(91px);
  }
  100% {
    opacity: 1;
    transform: rotate(180deg) scaleX(-1);
    filter: blur(91px);
  }
}
