@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

.root {
  @include not-selectable;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 400ms ease-in-out;
  transition-delay: 200ms;
  z-index: 10;

  .card {
    max-width: 50rem;
    width: 100%;
    max-height: calc(100% - 20rem);
    background: linear-gradient(
      185deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.08) inset,
      0px 0px 0px 1px rgba(255, 255, 255, 0.1),
      0px 0px 0px 2px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(19px);
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;

    @include mobile {
      max-width: 40rem;
    }
  }
  &.shown {
    pointer-events: initial;
    opacity: 1;
  }

  .header {
    @include radial-gradient-text-dark;
    @include subtitle-1-secondary;
    text-align: center;
    margin: 0.5rem 1rem 0.2rem 1rem;

    @include tablet {
      margin: 0.5rem 0.6rem 0.2rem 0.6rem;
    }
  }

  .content {
    border-radius: 0.7rem;
    border: 0.2rem solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    margin: 0.4rem;
    flex: 1;
    text-align: center;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet {
      padding: 3rem;
    }

    .title {
      @include linear-gradient-text-light;
      @include heading-5-primary;
    }

    .description {
      @include subtitle-1-secondary;
      color: $color-text-secondary;
      margin-top: 2.7rem;

      @include tablet {
        margin-top: 2.2rem;
      }
    }

    .button {
      background-color: radial-gradient(
          50% 50% at 50% 50%,
          rgba(255, 255, 255, 0.24) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        rgba(255, 255, 255, 0.04);
      margin-top: 2.6rem;

      @include tablet {
        margin-top: 2.2rem;
      }
    }
  }
}

.icon {
  width: 6rem;
  margin-bottom: 2.7rem;

  @include tablet {
    margin-bottom: 2.2rem;
  }
}

.error {
  text-decoration: underline;
}
