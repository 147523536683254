@import "styles/base/config";
@import "styles/base/typography";

.content {
  max-width: 37rem;
  margin: 0 auto;
}

.form {
  margin-bottom: 2rem;
}

.info {
  @include paragraph-2-primary;
  @include not-selectable;
  text-align: center;
  color: $color-text-primary;
  position: relative;
  transition: opacity 200ms ease;

  .amount {
    @include subtitle-2-secondary;
    border-bottom: 0.1rem solid #c6c6ca;
    font-weight: 600;
  }

  .copiesLoading {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    opacity: 0;
  }

  &.infoLoading {
    .infoText {
      opacity: 0;
    }

    .copiesLoading {
      opacity: 1;
    }
  }
}

.disclaimer {
  @include paragraph-3-primary;
  margin-top: 1rem;
  color: $color-text-secondary_lighter;
  text-align: center;

  .termsLink {
    border-bottom: 0.1rem solid $color-text-secondary_lighter;
    cursor: pointer;
  }
}
