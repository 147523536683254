@import "styles/base/config";
@import "styles/base/typography";

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 400ms ease;
}

.modal {
  max-width: 65rem;
  max-height: 50rem;
  min-width: 50rem;
  min-height: 4rem;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  outline: none;
  border-radius: 1.9rem;

  @include mobile {
    margin: 0 auto;

    min-width: 40rem;
    max-width: 50rem;
  }
}

.modalWrapper {
  background: linear-gradient(184.57deg, #09071c -3.39%, #131525 105.34%);
  box-shadow: 0px 4px 62px rgba(255, 255, 255, 0.12),
    0px 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(255, 255, 255, 0.1),
    inset 0px 1px 0px rgba(121, 118, 255, 0.5);
  border-radius: 1.9rem;
  max-width: 50rem;
  max-height: 45rem;
  position: relative;

  .modalContent {
    padding: 3rem 3.4rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    background-image: url(../../assets/images/modal/robot-head.png);
  }
}

.title {
  @include title-1-primary;
  background: linear-gradient(
    180deg,
    #ffffff 22.5%,
    rgba(255, 255, 255, 0.7) 100%
  );
  @include gradientText;
  margin-bottom: 3rem;
  white-space: pre-wrap;
}

.description {
  @include subtitle-3-primary;
  font-weight: 400;
  color: $color-text-secondary;
  white-space: pre-line;
}
