@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

$features_bg: #0d0f20;

@mixin container {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(255, 255, 255, 0.1),
    inset 0px 1px 0px rgba(255, 255, 255, 0.08);
  border-radius: 0.7rem;
}

@mixin container-border {
  border: 0.2rem solid rgba(255, 255, 255, 0.1);
}

.root {
  @include container;
  height: 100%;
  max-width: 50rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
  background-color: $features_bg;
}

.content {
  width: 100%;
  display: flex;
}

.featuresContainer {
  @include container-border;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 0.7rem;
  margin: 0.8rem 0.8rem 1.8rem 0.8rem;
  padding: 0.8rem;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  @include mobile {
    margin: 0.7rem 0.7rem 1.7rem 0.7rem;
  }

  .feature {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.left {
  width: 40%;
  display: flex;
  justify-content: flex-end;

  @include mobile {
    width: fit-content;
  }
}

.right {
  width: 60%;
  @include mobile {
    width: fit-content;
  }
}

.iconContainer {
  background: #161829ba;
  mix-blend-mode: luminosity;
  border-radius: 70px;
  width: 8.5rem;
  height: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  border: 1px solid #434451;

  @include mobile {
    width: 8.3rem;
    height: 8.3rem;
  }
}

.title {
  @include title-3-secondary;
  @include truncateText(1);
  color: $color-text-primary;
  font-weight: 600;
  margin-bottom: 0.2rem;

  @include mobile {
    max-width: fit-content;
    margin-bottom: 0.6rem;
  }
}

.info {
  @include paragraph-3-primary;
  @include truncateText(3);
  max-width: 20rem;
  white-space: pre-wrap;

  @include mobile {
    max-width: fit-content;
  }
}

.right {
  .listItem {
    margin-bottom: 0.6rem;
  }
}
.hiddenList {
  .right,
  .left {
    filter: blur(9.5px);
  }
}

.additionalListContainer {
  @include container-border;
  transition: opacity 0.2s linear;
  opacity: 0;
  background: $background-dark;
  cursor: pointer;
  border-radius: 0.7rem;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &:not(.additionalListActive) {
    overflow: hidden;
  }

  .additionalListText {
    @include paragraph-1-primary;
    text-align: center;
    max-height: 100%;
    margin: 0 4rem;
    white-space: pre-wrap;
  }
}

.additionalListActive {
  opacity: 1;
  overflow: auto;
}

.emptyText {
  @include title-1-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.listText {
  @include truncateText(1);
}

.dots {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  cursor: pointer;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.2);
  margin: 0 0.3rem;
  border: none;
  padding: 0;
  &.activeDot {
    background: #d9d9d9;
  }
}

.arrow {
  background-color: transparent;
  border: none;
  z-index: 2;
  position: relative;
  transition: opacity 200ms linear;

  .innerArrow {
    background-image: url(../../../../../../../../../assets/icons/upRightArrow.svg);
    width: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    height: 2rem;
    cursor: default;
    cursor: pointer;
  }

  &:hover {
    opacity: 0.7;
  }

  &.disabledArrow {
    opacity: 0;
    pointer-events: none;
    .innerArrow {
      cursor: default;
    }
  }

  .rightArrow {
    transform: rotate(45deg);
  }

  .leftArrow {
    transform: rotate(-135deg);
  }
}
