@import "styles/base/config";
@import "styles/base/typography";

.root {
  @include not-selectable;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4rem;
  transition: opacity 400ms ease-in-out;
  transition-delay: 200ms;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  height: 100%;

  &.shown {
    pointer-events: initial;
    opacity: 1;
  }

  .content {
    max-width: 80rem;
  }

  .title {
    max-width: 75rem;
    width: 100%;
  }

  .card {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .cardContent,
    .cardContainer {
      border-radius: 1.9rem;
    }

    .cardContent {
      height: 100%;
      background: linear-gradient(183.19deg, #101b27 3.15%, #030516 100%);
      box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.05),
        0px 0px 0px 1px rgba(255, 255, 255, 0.1),
        inset 0px 1px 0px rgba(121, 118, 255, 0.5);
      position: relative;
      padding: 2.7rem 3.2rem;
      display: flex;
      flex-direction: column;

      .cardInfo {
        position: relative;
        z-index: 1;
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background-image: url(../../../../../../assets/images/modal/robot-head.png);
        background-position: right;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 0;
      }

      .cardTitle {
        @include heading-4-secondary;
        color: $color-text-secondary_lighter;
      }

      .cardSubtitle {
        @include heading-3-secondary;
        line-height: 5rem;
        background: linear-gradient(91.75deg, #8b54ff 3.25%, #4bffd4 79.64%);
        @include gradientText;
        margin-top: 0.3rem;
        margin-bottom: 4rem;
      }
    }
  }
  .caption {
    @include title-1-secondary;
    line-height: 3.3rem;
    background: radial-gradient(
      97.57% 97.57% at 50% 50%,
      #ffffff 32.29%,
      rgba(255, 255, 255, 0) 100%
    );
    @include gradientText;
    width: fit-content;
    margin: 6rem auto 0 auto;
    text-align: center;
  }
}

.cardContainer {
  max-width: 35rem;
  height: 37rem;
  width: 100%;
}
