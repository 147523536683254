@import "styles/base/config";
@import "styles/base/typography";

.form {
  @include not-selectable;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.caption {
  @include paragraph-2-primary;
  text-align: center;
  color: $color-text-secondary;
  margin: 4rem 0 0 0;
}
