$xs: 375px; // Extra Small devices
$sm: 600px; // Small devices
$md: 768px; // Medium devices
$lg: 1024px; // Large devices
$xl: 1280px; // Extra Large devices
$xxl: 1440px; // 2K displays
$xxxl: 1920px; // Full HD displays
$max: 2560px; // Maximum resolution

$mobileSmall: $xs;
$mobile: $sm;
$tabletSmall: $md;
$tablet: $lg;
$desktopSmall: $xl;
$desktop: $xxl;
$desktopLarge: $xxxl;
$desktopMax: $max;

// for use in react components
:export {
  mobileSmall: $mobileSmall;
  mobile: $mobile;
  tabletSmall: $tabletSmall;
  tablet: $tablet;
  desktopSmall: $desktopSmall;
  desktop: $desktop;
  desktopLarge: $desktopLarge;
  desktopMax: $desktopMax;
}

// mixin for small mobile devices
@mixin mobileSmall {
  @media (max-width: #{$mobileSmall}) {
    @content;
  }
}

// mixin for mobile devices
@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

// mixin for small tablet devices
@mixin tabletSmall {
  @media (max-width: #{$tabletSmall}) {
    @content;
  }
}

// mixin for tablet devices
@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

// mixin for small desktop devices
@mixin desktopSmall {
  @media (max-width: #{$desktopSmall}) {
    @content;
  }
}

// mixin for desktop devices
@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content;
  }
}

// mixin for large desktop devices
@mixin desktopLarge {
  @media (max-width: #{$desktopLarge}) {
    @content;
  }
}

// mixin for max desktop devices
@mixin desktopMax {
  @media (max-width: #{$desktopMax}) {
    @content;
  }
}
