@import "styles/base/config";

.root {
  display: inline-block;
  position: relative;

  & > div {
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;
    border: 0.15rem solid currentColor;
    border-radius: 50%;
    animation: spin 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.3s;
    }
    &:nth-child(2) {
      animation-delay: -0.2s;
    }
    &:nth-child(3) {
      animation-delay: -0.1s;
    }
  }
}

// keyframes

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
