@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@mixin hide {
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.root .desktop {
  @include tablet {
    @include hide;
  }
}

.root .mobile {
  @include hide;
  @include tablet {
    height: auto;
    pointer-events: initial;
    opacity: 1;
  }
}
