@import "styles/base/config";
@import "styles/base/typography";

$card-bg: #101222;
$card-shadow: 0px 5.2px 15.6px rgba(0, 0, 0, 0.25),
  0px 0px 0px 2.6px rgba(0, 0, 0, 0.05),
  0px 0px 0px 1.3px rgba(255, 255, 255, 0.1),
  inset 0px 1.3px 0px rgba(255, 255, 255, 0.08);

.root {
  @include not-selectable;
  padding: 16rem 2rem 4rem 2rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 70rem;
  justify-content: center;

  @include tablet {
    max-width: 60rem;
    padding: 6rem 3rem 5rem 3rem;
  }
}

.mainSocial {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(100% - 10rem);
}

.card {
  background: $card-bg;
  box-shadow: $card-shadow;
  backdrop-filter: blur(24.7px);
  border-radius: 0.9rem;
  display: flex;
  height: 100%;
  transform: translateZ(0);
  flex: 1;
  overflow: hidden;
}

.contentWrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.content {
  position: relative;
  width: 100%;
}

.divider {
  width: 0.26rem;
  height: 100%;
  background: #171925;
}

.leftContent,
.rightContent {
  width: 50%;
}
.leftContent {
  border-top-right-radius: 0.9rem;
  border-bottom-right-radius: 0.9rem;
}

.rightContent {
  border-top-left-radius: 0.9rem;
  border-bottom-left-radius: 0.9rem;
}

.cover.left {
  width: 50%;
  left: 0;
  border-top-left-radius: 0.9rem;
  border-bottom-left-radius: 0.9rem;
}

.cover.right {
  width: 50%;
  right: 0;
  border-top-right-radius: 0.9rem;
  border-bottom-right-radius: 0.9rem;
}

.cover {
  height: 100%;
  top: 0;
  position: absolute;
  background: rgba(1, 3, 20, 0.6);
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: none;
  cursor: default;

  transition: opacity 500ms ease;
  &.hiddenCover {
    opacity: 0;
  }

  .coverImage {
    width: 7rem;
    height: 7rem;
  }

  .coverText {
    @include heading-4-secondary;
    color: $color-text-primary;
    margin-top: 1rem;
  }
}

.timeline {
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 45rem;
  iframe[id^="twitter-widget-"] {
    width: 100% !important;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.loadingLogo {
  width: 5rem;
  height: 5rem;
  will-change: transform;

  animation: logo 3.2s ease-in-out infinite;
}

.socialNetContainer {
  backdrop-filter: blur(8.71199px);
  position: relative;
  margin-top: 3rem;
  opacity: 0.85;

  &::before {
    background-image: url(../../../../../../../assets/images/socials/insta_bg.png);
  }
}

.socialNetContent {
  &::before {
    background: linear-gradient(91.06deg, #ff9c00 10.63%, #dd00b0 132.15%);
  }
}

.socialNetLink {
  @include paragraph-1-primary;
  font-weight: 500;
  color: $color-text-primary;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover {
    .socialNetLinkIcon {
      animation: arrow-move 800ms ease-in-out forwards;
    }
  }

  .socialNetLinkIcon {
    animation: arrow-reverse 200ms ease-in-out forwards;
  }
}

.socialNetLogo {
  flex-shrink: 0;
  margin-right: 1.5rem;
  width: 2.4rem;
  height: 2.4rem;

  @include tablet {
    width: 2rem;
    height: 2rem;
  }
}

.socialNetName {
  @include title-2-secondary;
  font-size: 2.8rem;
  font-weight: 600;
}
.socialNetLinkIcon {
  margin-left: 1rem;
}

// keyframes

@keyframes logo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes arrow-move {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(45deg);
  }

  50% {
    transform: translateX(5px) rotate(45deg);
  }

  100% {
    transform: translateX(0) rotate(45deg);
  }
}

@keyframes arrow-reverse {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0);
  }
}
