@import "styles/base/config";
@import "styles/base/typography";

$height-medium: 4.5rem;
$height-large: 4.9rem;

.root {
  position: relative;
  display: inline-block;
  background-color: transparent;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $height-medium;
  border-radius: 3rem;
  width: auto;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 4px 4px transparent, 0px 4px 42px transparent,
    0px 2px 0px transparent;
  background: $background-button;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6px);
  padding: 0 3.1rem;

  &.fill {
    background: $background-light;

    .content {
      color: $color-text-dark;
      background: transparent;
      background-clip: initial;
      -webkit-text-fill-color: initial;
      font-weight: 600;
    }
  }

  &.large {
    height: $height-large;

    .content {
      @include subtitle-3-primary;
      font-weight: 500;
    }
  }

  .buttonBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: $background-button-primary;
    transition: opacity 400ms ease-in;
    border-radius: 3.84rem;
    z-index: 1;
  }

  &.hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 42px #532ec4,
      0px 2px 0px #ffffff;
    animation: button-shadow 3s ease-in-out infinite;
    background: transparent;
    border: none;
    padding: 0.1rem 3.4rem;

    .buttonBackground {
      opacity: 1;
    }

    .content {
      @include linear-gradient-text;
    }
  }

  &:disabled:not(.loading) {
    opacity: 0.4;
    cursor: default;
  }

  .loadingLogo {
    position: absolute;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    max-height: 2.3rem;
  }
  &.loading {
    cursor: default;
    .content {
      pointer-events: none;
      opacity: 0;
    }
    .loadingLogo {
      pointer-events: none;
      opacity: 1;
      z-index: 2;
      animation: logo 3s ease-in-out infinite;
      will-change: transform;
    }
  }

  &:active:not(&:disabled):not(&.loading) {
    opacity: 0.7;
  }
}

.content {
  @include paragraph-1-primary;
  @include linear-gradient-text-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  position: relative;
  z-index: 2;
  margin-top: -2px;
}

.stars {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 3;
  pointer-events: none;
}

.starsSvg {
  width: 100%;
  height: auto;
  opacity: 0.7;
  overflow: initial;
}

.starGroup {
  transition: opacity 1s ease-in-out;
}

// keyframes

@keyframes logo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes button-shadow {
  0% {
    box-shadow: 0px 4px 4px transparent, 0px 4px 42px transparent,
      0px 2px 0px transparent;
  }
  50% {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 42px #532ec4,
      0px 2px 0px #ffffff;
  }
  100% {
    box-shadow: 0px 4px 4px transparent, 0px 4px 42px transparent,
      0px 2px 0px transparent;
  }
}
