@import "styles/base/config";

.root {
  height: 100%;
  position: relative;
  display: flex;
}

.content {
  height: 100%;
}
