@import "styles/base/config";
@import "styles/base/responsive";
@import "styles/base/typography";

.root {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.info {
  position: absolute;
  bottom: 4rem;
  transform: translateX(-50%);
  left: 50%;
  max-width: 75rem;
  width: calc(100% - 3rem);
  z-index: 99;

  @include tablet {
    top: 4rem;
    bottom: auto;
  }
}

.infoBar {
  width: fit-content;
  margin: 0.4rem auto;
  display: flex;
  justify-content: center;
}

.infoText {
  font-weight: 500;
}

.infoTextUnderline {
  @include title-2-secondary;
  border-bottom: 1px solid #c6c6ca;
}
