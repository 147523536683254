@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

$animationSpeedY: 45s;
$animationSpeedX: 25s;

.root {
  @include not-selectable;
  display: flex;
  width: calc(100% + 18rem);
  margin-left: -18rem;
  height: 100%;

  @include mobile {
    position: relative;
    top: -3rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    margin: 0 0 0 0;
    justify-content: center;
  }
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;

  @include mobile {
    overflow: initial;
    height: 100%;
    margin-top: 1rem;
  }
}

.scroll {
  animation: scrollCardsY $animationSpeedY linear infinite;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  backface-visibility: hidden;
  will-change: transform;

  @include mobile {
    height: 100%;
    flex-direction: row;
    overflow: initial;
    animation: scrollCardsX $animationSpeedX linear infinite;
  }

  &.evenColumn {
    position: relative;
    top: -8rem;

    @include mobile {
      top: 0;
      left: -8rem;
    }
  }
}

.card {
  margin: 1rem;

  @include mobile {
    flex-shrink: 0;
  }
}

// keyframes

@keyframes scrollCardsY {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes scrollCardsX {
  0% {
    transform: translateX(calc(-50%));
  }
  100% {
    transform: translateX(calc(-450% - 16rem));
  }
}
