$font-family-primary: SegoeUI, Helvetica, Arial, sans-serif;
$font-family-secondary: NeueBit, Helvetica, Arial, sans-serif;

// background
$background-main: #010314;
$background-dark: rgba(255, 255, 255, 0.04);
$background-light: #fff;
$background-button: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  rgba(255, 255, 255, 0.04);

$background-button-primary: radial-gradient(
  64.6% 94.84% at 50.06% 56.03%,
  #26215f 0%,
  #6633ee 62.5%,
  #fcfbff 100%
);

// colors

$color-divider: rgba(255, 255, 255, 0.06);
$color-error: #ff61ad;

// text colors
$color-text-primary: #fff;
$color-text-primary_darker: #e6e6e8;
$color-text-secondary: rgba(255, 255, 255, 0.6);
$color-text-secondary_lighter: rgba(255, 255, 255, 0.75);
$color-text-dark: #010314;

// text gradient
$radial-gradient-text: radial-gradient(
  50% 50% at 50% 50%,
  #ffffff 30%,
  rgba(255, 255, 255, 0.35) 100%
);
$radial-gradient-text-dark: radial-gradient(
  137.07% 694.26% at 50% 50%,
  #ffffff 0%,
  rgba(255, 255, 255, 0) 100%
);

$linear-gradient-text: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.73) 8.85%,
  #ffffff 100%
);
$linear-gradient-text-light: linear-gradient(
  180deg,
  #ffffff 22.5%,
  rgba(255, 255, 255, 0.7) 100%
);

$linear-gradient-text-dark: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.3) 8.85%,
  #ffffff 100%
);
