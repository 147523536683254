@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  @include tablet {
    max-height: 75rem;
    overflow: hidden;
  }
}

.mainForm {
  position: relative;
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  height: 100%;
  background-color: #101222;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(255, 255, 255, 0.1),
    inset 0px 1px 0px rgba(255, 255, 255, 0.08);

  @include tablet {
    padding: 2rem 1.5rem 2rem 1.5rem;
    background: linear-gradient(251.63deg, #0f1121 3.26%, #452c93 75.91%);
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(81.5px);
  }
  @include mobile {
    padding: 2rem 1rem 2rem 1rem;

    background: linear-gradient(203.17deg, #6b6093 11%, #362178 91.21%);
  }
}

.content {
  @include not-selectable;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.animatedLogo {
  padding: 1rem;
}

.logo {
  @include not-selectable;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .logoIcon {
    height: 5.5rem;
    margin: 0 auto;
    width: 100%;
  }

  .logoText {
    @include title-1-secondary;
    @include radial-gradient-text;
    text-align: center;
    margin-top: 0rem;
    height: 4rem;
    width: 100%;

    @include tablet {
      display: none;
    }
  }
}

.title {
  @include title-1-primary;
  @include linear-gradient-text-light;
  @include not-selectable;
  color: $color-text-primary;
  font-size: 3.1rem;
  margin-top: 2rem;
  text-align: center;
  white-space: pre-wrap;

  @include tablet {
    margin: 1rem 0 1rem 0;

    @include title-1-primary;
  }

  @include mobile {
    margin-top: 1.5rem;
  }
}

.titleEnd {
  @include heading-3-secondary;
  font-size: 4.5rem;
  @include tablet {
    font-size: 4.5rem;
  }
}

.titleUnderlined {
  @extend .titleEnd;
  @include tablet {
    font-size: 4.5rem;
  }
  border-bottom: 2px solid #c6c6ca;
}

.info {
  @include paragraph-2-primary;
  text-align: center;
  margin-top: 1rem;
  color: $color-text-primary;

  span {
    font-weight: 600;
    text-decoration: underline;
  }

  .copiesLoading {
    margin-bottom: -3px;
  }

  &.hideInfo {
    opacity: 0;
  }
}

.caption {
  @include paragraph-2-primary;
  @include not-selectable;
  text-align: center;
  color: $color-text-secondary;
  margin-top: 2rem;

  @include tablet {
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.formContainer {
  overflow: hidden;
}

.form {
  width: 100%;
  margin: 2rem auto 0 auto;

  @include tablet {
    margin: 1rem auto 1rem auto;
  }
}

.timerTicker {
  @include title-2-secondary;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  width: calc(100% + 6rem);
  margin: 1rem 0 0 -3rem;
  position: relative;
  padding: 0.7rem 0 0.5rem 0;

  @include tablet {
    background: transparent;
    margin: 2rem 0 0rem -1.5rem;
    width: calc(100% + 3rem);
  }

  @include mobile {
    margin: 2rem 0 0rem -1rem;
    width: calc(100% + 2rem);
  }

  &:before {
    background: linear-gradient(
      90deg,
      #0f1121 0%,
      rgba(15, 17, 33, 0) 50%,
      #0f1121 100%
    );

    @include tablet {
      background: transparent;
    }

    @include mobile {
      background: transparent;
    }

    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 50;
    content: "";
  }

  .timerTickerTrack {
    width: 100%;
    display: flex;
    overflow: hidden;
  }
}

.minimizeButtonContainer {
  background: linear-gradient(180deg, #040519 0%, rgba(4, 5, 25, 0) 100%);
  display: flex;
  justify-content: center;
  user-select: none;

  .minimizeButton {
    @include title-2-secondary;
    color: $color-text-primary;
    background: transparent;
    border: 0;
    outline: none;
    margin: 2rem;
    cursor: pointer;
  }
}

.expandButton {
  display: flex;
  justify-content: center;
}

.animatedMinimizeButton {
  justify-content: center;
}

.loginLinkContainer {
  position: absolute;
  top: 0;
  right: 2.9rem;
  margin-top: 2rem;

  @include tablet {
    display: flex;
    justify-content: center;
    position: relative;
    right: 0;
  }
}
.loginLink {
  line-height: normal;
  display: inline-block;
  text-decoration: none;
  color: $color-text-primary;

  .loginLinkText {
    border-bottom: 0.1rem solid $color-text-primary;
  }

  &:hover {
    .loginLinkTitle {
      opacity: 0.5;

      @include tablet {
        opacity: 1;
      }
    }
    @include mobile {
      .loginLinkTitle {
        opacity: 1;
      }
    }
    .loginLinkIcon {
      animation: arrow-move 800ms ease-in-out forwards;
    }
  }

  .loginLinkIcon {
    margin-left: 0.9rem;
    margin-bottom: -0.2rem;
    animation: arrow-reverse 200ms ease-in-out forwards;
  }
}

.loginLinkContainer .loginLink .loginLinkTitle {
  user-select: none;
  @include subtitle-2-secondary;
  font-weight: 500;

  @include tablet {
    font-size: 2.2rem;
  }
}

@keyframes arrow-move {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(45deg);
  }

  50% {
    transform: translateX(5px) rotate(45deg);
  }

  100% {
    transform: translateX(0) rotate(45deg);
  }
}

@keyframes arrow-reverse {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0);
  }
}
