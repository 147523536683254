@import "~minireset.css/minireset.min.css";
@import "./base/config";
@import "./base/typography";
@import "./base/fonts.scss";
@import "./base/responsive.scss";
@import "simplebar/dist/simplebar.min.css";

#root {
  height: 100%;
}

.twitter-timeline {
  width: 100%;
  max-height: calc(100% - 10rem);
}

/* Set the base font-size */
html {
  height: 100%;
  font-size: 20px;
  font-family: $font-family-primary;
  color: $color-text-primary;
  font-weight: 400;
  line-height: 1.2;
  touch-action: manipulation;

  @include desktopMax {
    font-size: 14px;
  }

  @include desktopLarge {
    font-size: 12px;
  }

  @include desktop {
    font-size: 10px;
  }

  @include desktopSmall {
    font-size: 10px;
  }

  @include tablet {
    font-size: 9px;
  }

  @include mobile {
    font-size: 8px;
  }
}

img {
  max-width: auto;
}

a {
  line-height: 1.2;
}

/* Set the rem scale */
body {
  height: 100%;
  font-size: 1.6rem;
  -webkit-tap-highlight-color: transparent;
}

// default class names from react-modal

.ReactModal__Content {
  opacity: 0;
  transform: translateY(100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(100px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input[data-com-onepassword-filled],
select[data-com-onepassword-filled],
textarea[data-com-onepassword-filled] {
  -webkit-text-fill-color: $color-text-primary !important;
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 0px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.globe {
  .scene-tooltip {
    z-index: 9999;
  }
}

.simplebar-scrollbar:before {
  background-color: #8c8d95;
  width: 0.5rem;
}

.simplebar-track.simplebar-vertical {
  width: 8px;
  background-color: transparent;
  margin-right: 0.2rem;
}

.simplebar-content-wrapper {
  height: 100% !important;
}
