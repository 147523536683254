@import "styles/base/config";
@import "styles/base/typography";
@import "styles/base/responsive";

$tabs-bg: #060819;
$tab-bg: linear-gradient(
  184.57deg,
  rgba(255, 255, 255, 0.08) -3.39%,
  rgba(255, 255, 255, 0) 105.34%
);
$tabs-border-color: rgba(255, 255, 255, 0.15);

.root {
  @include not-selectable;
  width: fit-content;
}

.container {
  position: relative;
}

.content {
  padding: 1.2rem 1.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 2rem;
  position: relative;
  background: $tabs-bg;
  border: 0.1rem solid $tabs-border-color;
  border-radius: 2.1rem;

  @include mobile {
    gap: 0 2rem;
  }
}

.tab {
  position: relative;
  width: 8.5rem;
  height: 8.5rem;
  background: $tab-bg;
  border-radius: 1.9rem;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  &.hovered {
    .tabTitle {
      opacity: 0.5;

      @include tablet {
        opacity: 1;
      }
    }
  }

  @include mobile {
    &.hovered {
      .tabTitle {
        opacity: 1;
      }
    }
  }

  @include mobile {
    width: 7rem;
    height: 7rem;
    border-radius: 1.5rem;
  }
}

.tabContent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .tabVideo {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 1.5rem;

    @include mobile {
      width: 7rem;
      height: 7rem;
      border-radius: 1.5rem;
    }
  }
}

.tabTitle {
  @include subtitle-1-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 400ms ease;
  color: $color-text-primary;
  text-shadow: 0px 2px 11px rgba(0, 0, 0, 0.78);
  text-transform: uppercase;
  padding: 0.3rem;
  opacity: 1;
  position: relative;
  z-index: 1;
}

.grayish {
  filter: grayscale(0.8);
}

.movedIcon {
  position: absolute;
  z-index: 1;
  transition: left 300ms ease;
  bottom: -0.8rem;

  @include tablet {
    bottom: -1.1rem;
  }
}

.iconBright {
  animation: fade 5s linear infinite;
}

.iconBright {
  position: absolute;
}

// keyframes

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
