@import "./config";
@import "./responsive";

/* Typography */

@mixin heading-1($fontFamily) {
  font-size: 15rem;
  font-weight: 700;
  font-family: $fontFamily;

  @include desktop {
    font-size: 13rem;
  }

  @include tablet {
    font-size: 12rem;
  }

  @include mobile {
    font-size: 10rem;
  }
}

@mixin heading-2($fontFamily) {
  font-size: 8rem;
  font-weight: 600;
  font-family: $fontFamily;

  @include desktop {
    font-size: 7rem;
  }

  @include tablet {
    font-size: 6rem;
  }
}

@mixin heading-3($fontFamily) {
  font-size: 6.5rem;
  font-weight: 700;
  font-family: $fontFamily;

  @include tablet {
    font-size: 5.4rem;
  }
}

@mixin heading-4($fontFamily) {
  font-size: 4.5rem;
  font-weight: 700;
  font-family: $fontFamily;
  line-height: 1;

  @include mobile {
    font-size: 3.8rem;
  }
}

@mixin heading-5($fontFamily) {
  font-size: 4rem;
  font-weight: 700;
  font-family: $fontFamily;
}

@mixin title-1($fontFamily) {
  font-weight: 700;
  font-size: 3.5rem;
  font-family: $fontFamily;

  @include tablet {
    font-size: 2.9rem;
  }
}

@mixin title-2($fontFamily) {
  font-weight: 700;
  font-size: 3rem;
  font-family: $fontFamily;

  @include mobile {
    font-size: 2.7rem;
  }
}

@mixin title-3($fontFamily) {
  font-weight: 700;
  font-size: 2.6rem;
  font-family: $fontFamily;

  @include mobile {
    font-size: 2.7rem;
  }
}

@mixin subtitle-1($fontFamily) {
  font-weight: 700;
  font-size: 2.3rem;
  font-family: $fontFamily;

  @include tablet {
    font-size: 2rem;
  }

  @include mobile {
    font-size: 1.9rem;
  }
}

@mixin subtitle-2($fontFamily) {
  font-weight: 700;
  font-size: 2rem;
  font-family: $fontFamily;
}

@mixin subtitle-3($fontFamily) {
  font-weight: 700;
  font-size: 1.8rem;
  font-family: $fontFamily;
}

@mixin paragraph-1($fontFamily) {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: $fontFamily;
}

@mixin paragraph-2($fontFamily) {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: $fontFamily;

  @include mobile {
    font-size: 1.3rem;
  }
}

@mixin paragraph-3($fontFamily) {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: $fontFamily;
}

@mixin heading-1-primary {
  @include heading-1($font-family-primary);
}

@mixin heading-1-secondary {
  @include heading-1($font-family-secondary);
}

@mixin heading-2-primary {
  @include heading-2($font-family-primary);
}

@mixin heading-2-secondary {
  @include heading-2($font-family-secondary);
}

@mixin heading-3-primary {
  @include heading-3($font-family-primary);
}

@mixin heading-3-secondary {
  @include heading-3($font-family-secondary);
}

@mixin heading-4-primary {
  @include heading-4($font-family-primary);
}

@mixin heading-4-secondary {
  @include heading-4($font-family-secondary);
}

@mixin heading-5-primary {
  @include heading-5($font-family-primary);
}

@mixin heading-5-secondary {
  @include heading-5($font-family-secondary);
}

@mixin title-1-primary {
  @include title-1($font-family-primary);
}

@mixin title-1-secondary {
  @include title-1($font-family-secondary);
}

@mixin title-2-primary {
  @include title-2($font-family-primary);
}

@mixin title-2-secondary {
  @include title-2($font-family-secondary);
}

@mixin title-3-primary {
  @include title-3($font-family-primary);
}

@mixin title-3-secondary {
  @include title-3($font-family-secondary);
}

@mixin subtitle-1-primary {
  @include subtitle-1($font-family-primary);
}

@mixin subtitle-1-secondary {
  @include subtitle-1($font-family-secondary);
}

@mixin subtitle-2-primary {
  @include subtitle-2($font-family-primary);
}

@mixin subtitle-2-secondary {
  @include subtitle-2($font-family-secondary);
}

@mixin subtitle-3-primary {
  @include subtitle-3($font-family-primary);
}

@mixin subtitle-3-secondary {
  @include subtitle-3($font-family-secondary);
}

@mixin paragraph-1-primary {
  @include paragraph-1($font-family-primary);
}

@mixin paragraph-1-secondary {
  @include paragraph-1($font-family-secondary);
}

@mixin paragraph-2-primary {
  @include paragraph-2($font-family-primary);
}

@mixin paragraph-2-secondary {
  @include paragraph-2($font-family-secondary);
}

@mixin paragraph-3-primary {
  @include paragraph-3($font-family-primary);
}

@mixin paragraph-3-secondary {
  @include paragraph-3($font-family-secondary);
}

@mixin gradientText {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// Important!!!
// If you want to use a custom gradient background, you need to keep the order:
// background: ...;
// @include gradientText;

@mixin radial-gradient-text {
  background: $radial-gradient-text;
  @include gradientText;
}

@mixin radial-gradient-text-dark {
  background: $radial-gradient-text-dark;
  @include gradientText;
}

@mixin linear-gradient-text {
  background: $linear-gradient-text;
  @include gradientText;
}

@mixin linear-gradient-text-light {
  background: $linear-gradient-text-light;
  @include gradientText;
}

@mixin linear-gradient-text-dark {
  background: $linear-gradient-text-dark;
  @include gradientText;
}

/* Hide image alt text (even in firefox) */
img {
  color: transparent;
}

@mixin truncateText($lines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  //Parse CSS and add vendor prefixes to rules by Can I Use
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

@mixin not-selectable {
  user-select: none;
}

@mixin pulse {
  animation: pulse-keyframes 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@mixin primary-pulse($alpha: 0.1) {
  @include pulse;
  background-color: adjust-color(#6633ee, $alpha: $alpha - 1);
}

@keyframes pulse-keyframes {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
